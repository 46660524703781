import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Router } from './Router';
import AppUrlListener from './components/AppUrlListener/AppUrlListener';
import BundleUpdate from './components/BundleUpdate/BundleUpdate';
import { FacebookPixel } from './components/FacebookPixel/FacebookPixel';
import { GoogleTagManager } from './components/GoogleTagManager/GoogleTagManager';
import { YandexMetrica } from './components/YandexMetrica/YandexMetrica';
import {
  CourseReviewProvider,
  DailyRewardProvider,
  LanguageProvider,
  ModalProvider,
  PushProvider,
  TitleBackProvider,
  UserProvider,
} from './providers';
import { store } from './stores';
import { RewardProvider } from './providers/RewardProvider';
import { TelegramApp } from './components/TelegramApp';
import { NotificationsWebsocketProvider } from './providers/NotificationsWebsocketProvider';
import SEOProvider from './components/SEO/SEOProvider';
import { SaveUserLanguageOnServer } from './components/SaveUserLanguageOnServer';
import OrganizationLdJson from './modules/Main/seo/OrganizationLdJson';
import SoftwareApplicationLdJson from './modules/Main/seo/SoftwareApplicationLdJson';
import WebSiteLdJson from './modules/Main/seo/WebSiteLdJson';
import { ContextMenu } from './components/ContextMenu/ContextMenu';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { walletsListConfiguration } from './config/wallets';
import { BidServiceProvider } from './providers/BidServiceProvider/BidServiceProvider';
import getPlatform from './utils/getPlatform';
import { PlatformsEnum } from './entities/Platforms/platforms';
import { useReferralCode } from './hooks/useReferalCode';

const App: React.FC = () => {
  const manifestUrl = `${process.env.REACT_APP_API_URL}/tonconnect-manifest.json`;
  useReferralCode();
  const platform = getPlatform();
  return (
    <BrowserRouter>
      <TonConnectUIProvider
        manifestUrl={manifestUrl}
        uiPreferences={{ theme: THEME.LIGHT }}
        walletsListConfiguration={walletsListConfiguration}
      >
        <BundleUpdate />
        <LanguageProvider>
          <AppUrlListener />
          <RewardProvider>
            <ModalProvider>
              <Provider store={store}>
                <UserProvider>
                  <DailyRewardProvider>
                    <SaveUserLanguageOnServer />
                    <NotificationsWebsocketProvider>
                      <TelegramApp />
                      <PushProvider />
                      <GoogleTagManager />
                      <YandexMetrica />
                      <FacebookPixel />
                      <ToastContainer
                        toastClassName={
                          platform !== PlatformsEnum.WEB ? 'app-toast' : undefined
                        }
                      />
                      <SEOProvider>
                        <OrganizationLdJson />
                        <SoftwareApplicationLdJson />
                        <WebSiteLdJson />
                        <CourseReviewProvider>
                          <TitleBackProvider>
                            <ContextMenu>
                              <BidServiceProvider>
                                <Router />
                              </BidServiceProvider>
                            </ContextMenu>
                          </TitleBackProvider>
                        </CourseReviewProvider>
                      </SEOProvider>
                    </NotificationsWebsocketProvider>
                  </DailyRewardProvider>
                </UserProvider>
              </Provider>
            </ModalProvider>
          </RewardProvider>
        </LanguageProvider>
      </TonConnectUIProvider>
    </BrowserRouter>
  );
};

export default App;
